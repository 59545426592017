<template>
  <div>
    <v-simple-table class="table mt-2">
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-left">Product Name</th>
            <th class="text-left">Description</th>
            <th class="text-left">Option</th>
            <th class="text-left">Tag</th>
            <th class="text-left">Price (RM)</th>
            <th class="text-left">Price Before Discount (RM)</th>
            <th class="text-left"></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, i) in products" :key="i">
            <td>{{ item.name }}</td>
            <td style="width: 20%">{{ item.description }}</td>
            <td>
              <ul id="prod_mod">
                <li v-for="mod in item.modifier_sets" :key="mod._id">
                  {{ mod.name }} 
                  <ul id="prod_mod_detail">
                    <li v-for="mod_detail in mod.modifiers" :key="mod_detail._id">
                      {{ mod_detail.name }} | RM {{ mod_detail.amount }}
                    </li>
                  </ul>
                </li>
              </ul>
            </td>
            <td>
              <ul id="prod_mod">
                <li v-for="tag in item.tags" :key="tag._id">
                  {{ tag.name }}
                </li>
              </ul>
            </td>
            <td>{{ item.unit_price }}</td>
            <td>{{ item.before_discount }}</td>
            <td>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon color="blue" v-bind="attrs" v-on="on">
                    <v-icon>mdi-pencil</v-icon>
                  </v-btn>
                </template>
                <span>Edit</span>
              </v-tooltip>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon color="error" v-bind="attrs" v-on="on">
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </template>
                <span>Remove</span>
              </v-tooltip>
              <!-- <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn icon color="green" v-bind="attrs" v-on="on">
                      <v-icon>mdi-account-arrow-left</v-icon>
                    </v-btn>
                  </template>
                  <span>Impersonate</span>
                </v-tooltip> -->
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "Products",
  data() {
    return {};
  },

  computed: {
    ...mapGetters({
      products: "getProducts",
      selectedOutlet: "getSelectedOutlet",
    }),
  },

  watch: {
    selectedOutlet: "fetchAPI",
  },

  beforeMount() {
    this.$store.dispatch("startLoad");
    this.$store.dispatch("fetchProducts");
  },

  methods: {
    fetchAPI() {
      this.$store.dispatch("startLoad");
      this.$store.dispatch("fetchProducts");
    },
  },
};
</script>
<style lang="scss" scoped>
.table {
  overflow-y: auto;
}
</style>
